import React from 'react';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { Box } from '@chakra-ui/react';
import Wrapper from '../../shared/components/Wrapper';
import { Deal } from '../../shared/types/deal';

interface IDeliveryMainInfo {
  deal: Deal;
}

const DealMainInfo: React.FC<IDeliveryMainInfo> = ({ deal }) => {
  return (
    <Wrapper>
      <>
        <Box mt={2}>
          <ButtonLink
            colorScheme={'blue'}
            to={`${Paths.users}/${deal.createdById}`}
          >
            Open creator
          </ButtonLink>
        </Box>
        <InfoValue value={deal.ownerOrganizationId} label={'Organization'} />
        <InfoValue value={deal.id} label={'Id'} />
        <InfoValue value={deal.priority} label={'Priority'} />
        <InfoValue value={deal.name} label={'Name'} />
        <InfoValue value={deal.description} label={'Description'} />
        <InfoValue
          value={toPrettyDate(deal.createdAt)}
          label={'Creation date'}
        />
        <InfoValue
          value={toPrettyDate(deal.modifiedAt)}
          label={'Last modified'}
        />
      </>
    </Wrapper>
  );
};

export default DealMainInfo;
