import { baseApi } from './BaseService';
import {
  GetAllDealsResponse,
  GetDealByIdResponse,
} from '../responseTypes/deal';

const baseUrl = 'deals';

export const dealsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllDeals: build.query<
      GetAllDealsResponse,
      {
        sorting: string;
        order: string;
        limit: number;
        offset: number;
        query?: string;
        geo?: string;
        category?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
    }),
    getDealById: build.query<
      GetDealByIdResponse,
      { dealId: number }
    >({
      query: ({ dealId }) => ({
        url: `${baseUrl}/${dealId}`,
      }),
      providesTags: ['Deal'],
    }),
  }),
});
