import React from 'react';
import { StyledTable, StyledTableContainer } from './styled';

const ResponsiveTable: React.FC<Props> = ({ children, ...props }) => {
  return (
    <StyledTableContainer>
      <StyledTable {...props}>
        {children}
      </StyledTable>
    </StyledTableContainer>
  );
};

export default ResponsiveTable;
