import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { ordersApi } from '../../store/services/OrdersService';
import {
  Grid,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { toPrettyDate } from '../../shared/utils/utils';
import SortBlock from '../../shared/components/SortBlock';
import { sortOptions } from './helpers';

const Orders: React.FC = () => {
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [defaultSort] = useState('');
  const [sort, setSort] = useState(defaultSort);

  const { data, refetch, isFetching } = ordersApi.useGetOrdersQuery({});

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [debouncedQuery]);

  const items = useMemo(() => {
    return [...(data?.orders ?? [])];
  }, [sort, data]);

  return (
    <MainLayout>
      <Grid gridTemplateColumns={'1fr 2fr'} mb={4} gridGap={'1rem'}>
        <SortBlock
          value={defaultSort}
          onChange={setSort}
          options={sortOptions}
        />
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
      </Grid>

      {(!isFetching && (
        <Table
          mt={'1rem'}
          backgroundColor={'white'}
          boxShadow="md"
          borderRadius={'md'}
        >
          <Thead>
            <Tr>
              <Th>Id</Th>
              <Th>Request Id</Th>
              <Th>Email</Th>
              <Th>Geo</Th>
              <Th>Status</Th>
              <Th>Category</Th>
              <Th>Created at</Th>
              <Th>Sent at</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((order) => (
              <Tr key={order.id}>
                <Td>{order.id}</Td>
                <Td>{order.requestId}</Td>
                <Td>{order.email}</Td>
                <Td>{order.geo}</Td>
                <Td>{order.status}</Td>
                <Td>{order.category}</Td>
                <Td>{toPrettyDate(order.createdAt)}</Td>
                <Td>{toPrettyDate(order.sentAt)}</Td>
                <Td>
                  <ButtonLink color={'blue'} to={`${Paths.orders}/${order.id}`}>
                    Open
                  </ButtonLink>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )) || (
        <Grid gap={'1rem'} gridTemplateColumns={'1fr'} mt={'1rem'}>
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
          <Skeleton w={'100%'} h={'3rem'} />
        </Grid>
      )}
    </MainLayout>
  );
};

export default Orders;
