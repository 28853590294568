import { baseApi } from './BaseService';
import {
  GetAllOrdersResponse,
  GetOrderByIdResponse,
} from '../responseTypes/order';

const baseUrl = 'orders';

export const ordersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOrders: build.query<
      GetAllOrdersResponse,
      {
        sorting?: string;
        order?: string;
        limit?: number;
        offset?: number;
        query?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
    }),
    getOrderById: build.query<GetOrderByIdResponse, { orderId: number }>({
      query: ({ orderId }) => ({
        url: `${baseUrl}/${orderId}`,
      }),
      providesTags: ['Order'],
    }),
  }),
});
