import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { organizationsApi } from '../services/OrganizationsService';
import { Organization } from '../../shared/types/organization';

interface IOrganizationsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  organizations: Organization[];
}

const initialState: IOrganizationsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  organizations: [],
};

const slice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      organizationsApi.endpoints.getAllOrganizations.matchFulfilled,
      (state: IOrganizationsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        // const { offset } = args;
        // if (offset === 0)
        //   return { ...state, ...payload, emailNotifications: payload.emailNotifications };
        return {
          ...state,
          ...payload,
          organizations: [...state.organizations, ...payload.organizations],
        };
      },
    );
    builder.addMatcher(
      organizationsApi.endpoints.createOrganization.matchFulfilled,
      (state: IOrganizationsSlice, { payload: { organization } }) => {
        return {
          ...state,
          organizations: [...state.organizations, organization],
        };
      },
    );
    builder.addMatcher(
      organizationsApi.endpoints.updateOrganizationById.matchFulfilled,
      (state: IOrganizationsSlice, { payload: { organization } }) => {
        return {
          ...state,
          organizations: state?.organizations?.map(
            (organization) =>
              (organization.id === organization.id && organization) ||
              organization,
          ),
        };
      },
    );
    builder.addMatcher(
      organizationsApi.endpoints.deleteOrganizationById.matchFulfilled,
      (state: IOrganizationsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { organizationId } = args;
        return {
          ...state,
          organizations: state?.organizations?.filter(
            (organization) => organization.id !== organizationId,
          ),
        };
      },
    );
  },
});

export default slice.reducer;

export const selectOrganizations = (state: RootState) =>
  state.organizations.organizations;
export const selectOrganizationsTotalEntities = (state: RootState) =>
  state.organizations.totalEntities;
export const selectOrganizationsHasMore = (state: RootState) => {
  const total = state.organizations.totalEntities;
  return (
    total === null ||
    (total > 0 && state.organizations.organizations.length < total)
  );
};
