import React from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { useParams } from 'react-router-dom';
import { usersApi } from '../../store/services/UsersService';
import UserData from './UserData';
import { Flex, Spinner } from '@chakra-ui/react';

const User: React.FC = () => {
  const { userId } = useParams();
  const { data, isLoading } = usersApi.useGetUserByIdQuery({
    userId: Number(userId) || 0,
  });

  return (
    <MainLayout>
      {isLoading && (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
      {data?.user && <UserData user={data.user} />}
    </MainLayout>
  );
};

export default User;
