import React from 'react';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { Lead } from '../../shared/types/lead';
import { LeadStatus } from '../../shared/enums/lead-status';
import { LeadCategory } from '../../shared/enums/lead-category';
import NavLink from '../../shared/components/NavLink';
import { Paths } from '../../router/routes';
import { BsFillPeopleFill } from 'react-icons/bs';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import {Table, Td, Thead, Tr} from "@chakra-ui/react";
import {Deal} from "../../shared/types/deal";
import {StyledActionsRow, StyledEndClient} from "../deals/styled";
import {SendRequest} from "../../shared/types/sendRequest";

interface ILeadData {
  lead: Lead;
}

const LeadInfo: React.FC<ILeadData> = ({ lead }) => {
  return (
    <>
        <Table>
            <Thead>
                <Td width={"33%"}>
                  Lead Details
                </Td>
                <Td>
                    API Requests
                </Td>
                <Td>
                    Lead Stages
                </Td>

                <Tr>
                    <InfoValue value={lead?.id} label={'Id'} />
                    <InfoValue value={lead?.flow} label={'Flow'} />
                    <InfoValue value={lead?.requestId} label={'Request Id'} />
                    <ButtonLink
                        colorScheme={'blue'}
                        to={`${Paths.deals}/${lead.dealId.toString()}`}
                    >
                        Open Deal
                    </ButtonLink>
                    <InfoValue value={lead?.dealId} label={'Deal Id'} />
                    <InfoValue value={lead?.phoneNumber} label={'Phone'} />
                    <InfoValue value={lead?.postponed} label={'Postponed'} />
                    <InfoValue value={lead?.userAgent} label={'User Agent'} />
                    <InfoValue value={lead?.userIp} label={'User Ip'} />
                    <InfoValue value={LeadStatus[lead?.status]} label={'Status'} />
                    <InfoValue value={LeadCategory[lead?.category]} label={'Category'} />
                    <InfoValue value={lead?.geo} label={'Geo'} />
                    <InfoValue value={lead?.email} label={'Email'} />
                    <InfoValue value={lead?.firstName} label={'First Name'} />
                    <InfoValue value={lead?.lastName} label={'Last Name'} />

                    <InfoValue value={toPrettyDate(lead?.createdAt)} label={'Created'} />
                    <InfoValue value={toPrettyDate(lead?.sentAt)} label={'Sent'} />
                </Tr>
                <Tr>
                    <Table>
                    </Table>
                    {lead.sendRequests.map((sr: SendRequest) => (
                        <Tr key={sr.createdAt}>
                            <Td>{sr.id}</Td>
                            <Td>
                                <div>
                                    <p>{sr.url}</p>
                                    <StyledEndClient>for {sr.method}</StyledEndClient>
                                </div>
                            </Td>
                            <Td>{toPrettyDate(sr.createdAt)}</Td>
                            <Td>
                                <StyledActionsRow>
                                    <ButtonLink
                                        color={'blue'}
                                        to={`${Paths.deals}/${sr.id}`}
                                    >
                                        Show more
                                    </ButtonLink>
                                    <ButtonLink
                                        color={'blue'}
                                        to={`${Paths.organizations}/${sr}`}
                                    >
                                        Company
                                    </ButtonLink>
                                </StyledActionsRow>
                            </Td>
                        </Tr>
                    ))}
                </Tr>
            <Tr>
                asdasd
            </Tr>
            </Thead>

        </Table>

    </>
  );
};

export default LeadInfo;
