import {
  selectCurrentUser,
  selectIsLoadingUser,
} from '../../store/slices/authSlice';
import { useAppSelector } from '../../store/hooks';

export const useIsAuthed = () => {
  const user = useAppSelector(selectCurrentUser);
  const isLoadingUser = useAppSelector(selectIsLoadingUser);

  return { isAuthed: !!user, isLoadingUser };
};
