import React, { ChangeEvent } from 'react';
import { Select } from '@chakra-ui/react';
import { SortOptions } from "../types/mixed";

interface ISortBlock {
  value: number | string;
  onChange: Function;
  options: SortOptions;
}

const SortBlock = ({ value, onChange, options, ...props }: ISortBlock) => {
  const changeValue = ({ target }: ChangeEvent<HTMLSelectElement>) => onChange(target.value)

  return (
    <Select
      onChange={changeValue}
      backgroundColor={'white'}
      {...props}
      defaultValue={value}
    >
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

export default SortBlock;
