import {
  Badge,
  Button,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { usersApi } from '../../store/services/UsersService';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import { toPrettyDate } from '../../shared/utils/utils';
import { useListFilters } from '../../shared/hooks/useListFilters';
import {
  PROFILES_SORTING_CREATION_DATE,
  PROFILES_SORTING_EMAIL,
  PROFILES_SORTING_FULL_NAME,
} from '../../shared/constants';
import SortableTh from '../../shared/components/table/SortableTH';
import { useAppSelector } from '../../store/hooks';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useDebounce } from '../../shared/hooks/useDebounce';
import {
  StyledFiltersWrapper,
  StyledSelectedActionsWrapper,
  StyledSelectedInfo,
} from './styled';
import Input from '../../shared/components/ui/Input/Input';
import useItemsSelect from '../../shared/hooks/useItemsSelect';
import { selectUsers, selectUsersHasMore } from '../../store/slices/usersSlice';

const Users: React.FC = () => {
  const [limit] = useState(20);
  const users = useAppSelector(selectUsers);
  const hasMore = useAppSelector(selectUsersHasMore);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const { sorting, sortBy, order, orderBy } = useListFilters();
  const [trigger] = usersApi.useLazyGetAllUsersQuery();

  const [updateUserById, { isLoading, isSuccess }] =
    usersApi.useUpdateUserByIdMutation();

  const ids = useMemo(() => users.map((user) => user.id), [users]);
  const {
    selectItem,
    selectedItems,
    selectAll,
    isSelected,
    isAllSelected,
    isIndeterminate,
  } = useItemsSelect(ids);

  const loadMore = (reload: boolean = false) => {
    trigger({
      limit,
      offset: (!reload && users.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
    });
  };

  useEffect(() => loadMore(true), [debouncedQuery, sorting, order]);

  const changeStatusMultiple = (isActive: boolean) => async () => {
    for (const user of users) {
      if (selectedItems.includes(user.id)) {
        const { id, firstName, lastName, lang, contactInfo, description } =
          user;
        await updateUserById({
          userId: id,
          data: {
            firstName,
            lastName,
            lang,
            isActive,
            contactInfo,
            description,
          },
        });
      }
    }
  };

  return (
    <MainLayout>
      {(!!selectedItems.length && (
        <StyledSelectedActionsWrapper>
          <Button
            colorScheme={'red'}
            onClick={changeStatusMultiple(false)}
            isLoading={isLoading}
          >
            Suspend
          </Button>
          <Button
            colorScheme={'green'}
            onClick={changeStatusMultiple(true)}
            isLoading={isLoading}
          >
            Activate
          </Button>
          <div />
          <StyledSelectedInfo>
            <p>Profiles selected: {selectedItems.length}</p>
          </StyledSelectedInfo>
        </StyledSelectedActionsWrapper>
      )) || (
        <StyledFiltersWrapper>
          <div />
          <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
        </StyledFiltersWrapper>
      )}

      <InfiniteScroll
        dataLength={users.length}
        next={loadMore}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isIndeterminate={isIndeterminate}
                    isChecked={isAllSelected}
                    onChange={selectAll}
                  />
                </Th>
                <Th>Company</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_FULL_NAME}
                >
                  Name
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_EMAIL}
                >
                  Email
                </SortableTh>
                <Th>Status</Th>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={PROFILES_SORTING_CREATION_DATE}
                >
                  Created
                </SortableTh>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {users.map((user) => (
                <Tr key={user.id}>
                  <Td>
                    <Checkbox
                      onChange={selectItem(user.id)}
                      isChecked={isSelected(user.id)}
                    />
                  </Td>
                  <Td>
                    <Text noOfLines={2} title={user.firstName}>
                      {user.firstName}
                    </Text>
                    <Text noOfLines={2} title={user.lastName}>
                      {user.lastName}
                    </Text>
                  </Td>
                  <Td>{user.email}</Td>
                  <Td>
                    {(user.isActive && (
                      <Badge colorScheme="green">Active</Badge>
                    )) || <Badge colorScheme="red">Pending</Badge>}
                  </Td>
                  <Td>{toPrettyDate(user.createdAt)}</Td>
                  <Td>
                    <ButtonLink color={'blue'} to={`${Paths.users}/${user.id}`}>
                      Open
                    </ButtonLink>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Users;
