import React from 'react';
import { useParams } from 'react-router-dom';
import { organizationsApi } from '../../store/services/OrganizationsService';
import MainLayout from '../../shared/components/MainLayout';
import OrganizationData from './OrganizationData';
import { Flex, Spinner } from '@chakra-ui/react';

const Organization: React.FC = () => {
  const { organizationId } = useParams();
  const { data } = organizationsApi.useGetOrganizationByIdQuery({
    organizationId: Number(organizationId) || 0,
  });

  return (
    <MainLayout>
      {(data?.organization && (
        <>
          <OrganizationData organization={data?.organization} />
        </>
      )) || (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
    </MainLayout>
  );
};

export default Organization;
