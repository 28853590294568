import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import MainLayout from '../../shared/components/MainLayout';

const Dashboard: React.FC = () => {
  return (
    <MainLayout>
      <Flex justifyContent={'center'} alignItems={'center'} h={'100%'} flexDirection={"column"}>
        <Text>Welcome to Inhousemafia admin panel!</Text>
        <Text> This page is for the future charts and stats 😃</Text>
      </Flex>
    </MainLayout>
  );
};

export default Dashboard;
