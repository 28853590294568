import React from 'react';
import LeadInfo from './LeadInfo';
import Wrapper from '../../shared/components/Wrapper';
import { Lead } from '../../shared/types/lead';

interface ILeadData {
  lead: Lead;
}

const LeadData: React.FC<ILeadData> = ({ lead }) => {
  return (
    <Wrapper>
      <>
        <LeadInfo lead={lead} />
      </>
    </Wrapper>
  );
};

export default LeadData;
