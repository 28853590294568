import React, { useEffect } from 'react';
import { Grid, Switch } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { User } from '../../shared/types/user';
import { UpdateUserByIdRequest } from '../../store/responseTypes/user';
import { usersApi } from '../../store/services/UsersService';
import { Language } from '../../shared/types/language';
import { mapableEnum } from '../../shared/utils/utils';
import FormInput from '../../shared/components/FormInput';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import FormSelect from '../../shared/components/FormSelect';
import { StyledSwitch } from './styled';

interface IUserForm {
  user: User;
  setEdit: (T: boolean) => void;
}

const UserForm: React.FC<IUserForm> = ({ user, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateUserByIdRequest>();
  const [updateUserById, { isLoading, isSuccess }] =
      usersApi.useUpdateUserByIdMutation();

  useEffect(() => {
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      lang: user?.lang,
      description: user?.description,
      contactInfo: user?.contactInfo,
      isActive: !user?.isActive,
    });
  }, [user]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UpdateUserByIdRequest> = async (data) => {
    await updateUserById({
      userId: user.id,
      data: {
        ...data,
        isActive: !data.isActive,
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormInput name={'fullName'} ph={'Name'} register={register} />
        <FormSelect
          register={register}
          ph={'Language'}
          name={'language'}
          options={mapableEnum(Language)}
        />
        <StyledSwitch>
          <p>Suspended</p>
          <Switch colorScheme={'red'} {...register('isActive')} />
        </StyledSwitch>
        <FormButtonUpdate isLoading={isLoading} />
      </Grid>
    </form>
  );
};

export default UserForm;
