import React from 'react';
import { User } from '../../shared/types/user';
import Wrapper from '../../shared/components/Wrapper';
import { useEditMode } from '../../shared/hooks/useEditMode';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';
import UserForm from "./UserForm";
import UserInfo from "./UserInfo";
import {Organization} from "../../shared/types/organization";

interface IUserData {
  user: User;
  organization?: Organization;
}

const UserData: React.FC<IUserData> = ({ user, organization }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <UserForm user={user} setEdit={setEdit} />) || (
          <UserInfo user={user} organization={organization} />
        )}
      </>
    </Wrapper>
  );
};

export default UserData;
