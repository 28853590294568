import React, { useEffect } from 'react';
import { Organization } from '../../shared/types/organization';
import { Grid } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UpdateOrganizationRequest } from '../../store/responseTypes/organization';
import { organizationsApi } from '../../store/services/OrganizationsService';
import FormButtonUpdate from '../../shared/components/FormButtonUpdate';
import FormInput from '../../shared/components/FormInput';

interface IOrganizationForm {
  organization: Organization;
  setEdit: (T: boolean) => void;
}

const OrganizationForm: React.FC<IOrganizationForm> = ({ organization, setEdit }) => {
  const { register, handleSubmit, reset } = useForm<UpdateOrganizationRequest>();
  const [updateOrganizationById, { isLoading, isSuccess }] =
    organizationsApi.useUpdateOrganizationByIdMutation();

  useEffect(() => {
    reset({
      name: organization?.name,
      description: organization?.description,
    });
  }, [organization]);

  useEffect(() => {
    if (isSuccess) setEdit(false);
  }, [isSuccess]);

  const onSubmit: SubmitHandler<UpdateOrganizationRequest> = async (data) => {
    await updateOrganizationById({ organizationId: organization.id, data });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid rowGap={'1rem'} gridTemplateColumns={'1fr'}>
        <FormInput name={'name'} ph={'Name'} register={register} />
        <FormInput name={'description'} ph={'Description'} register={register} />        
        <FormButtonUpdate isLoading={isLoading} />
      </Grid>
    </form>
  );
};

export default OrganizationForm;
