import React, { useEffect, useMemo, useState } from 'react';
import MainLayout from '../../shared/components/MainLayout';
import { Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import ButtonLink from '../../shared/components/ui/Button/ButtonLink';
import { Paths } from '../../router/routes';
import InfiniteScroll from '../../shared/components/custom/InfiniteScroll/InfiniteScroll';
import { useListFilters } from '../../shared/hooks/useListFilters';
import { useSelector } from 'react-redux';
import SortableTh from '../../shared/components/table/SortableTH';
import { toPrettyDate } from '../../shared/utils/utils';
import {
  StyledActionsRow,
  StyledEndClient,
  StyledFiltersWrapper,
} from './styled';
import ResponsiveTable from '../../shared/components/table/ResponsiveTable';
import Card from '../../shared/components/ui/Card/Card';
import SortBlock from '../../shared/components/SortBlock';
import Input from '../../shared/components/ui/Input/Input';
import { useDebounce } from '../../shared/hooks/useDebounce';
import {
  DEAL_SORTING_CAPACITY,
  DEAL_SORTING_CREATION_DATE,
  DEAL_SORTING_PRIORITY,
} from './constants';
import { Deal } from '../../shared/types/deal';
import { dealsApi } from '../../store/services/DealsService';
import { selectDeals, selectDealsHasMore } from '../../store/slices/dealsSlice';

const Deals = () => {
  const [limit] = useState(20);
  const [category, setCategory] = useState(1);
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);
  const [trigger] = dealsApi.useLazyGetAllDealsQuery();
  const deals = useSelector(selectDeals);
  const hasMore = useSelector(selectDealsHasMore);
  const { sorting, sortBy, order, orderBy } = useListFilters();

  const loadDeals = (reload: boolean = false) => {
    trigger({
      limit,
      offset: (!reload && deals.length) || 0,
      sorting,
      order,
      query: debouncedQuery,
    });
  };

  useEffect(() => {
    loadDeals(true);
  }, [debouncedQuery, sorting, order]);

  const filteredDeals = useMemo(
    () =>
      (category && deals.filter((deal) => deal.category === category)) || deals,
    [category, deals],
  );

  return (
    <MainLayout>
      <StyledFiltersWrapper>
        <SortBlock
          value={category}
          onChange={setCategory}
          options={[{ value: '', label: 'Show all' }]}
        />
        <div />
        <Input name={'query'} placeholder={'Search...'} onChange={setQuery} />
      </StyledFiltersWrapper>

      <InfiniteScroll
        dataLength={deals.length}
        next={loadDeals}
        hasMore={hasMore}
      >
        <Card noPadding>
          <ResponsiveTable>
            <Thead>
              <Tr>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DEAL_SORTING_CREATION_DATE}
                >
                  Status
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DEAL_SORTING_PRIORITY}
                >
                  Title
                </SortableTh>
                <SortableTh
                  sortBy={sortBy}
                  orderBy={orderBy}
                  sorting={sorting}
                  order={order}
                  field={DEAL_SORTING_CAPACITY}
                >
                  Creation date
                </SortableTh>
                <Th style={{ width: 0 }} />
              </Tr>
            </Thead>
            <Tbody>
              {filteredDeals.map((deal: Deal) => (
                <Tr key={deal.id}>
                  <Td>{deal.priority}</Td>
                  <Td>
                    <div>
                      <p>{deal.name}</p>
                      <StyledEndClient>for {deal.description}</StyledEndClient>
                    </div>
                  </Td>
                  <Td>{toPrettyDate(deal.createdAt)}</Td>
                  <Td>
                    <StyledActionsRow>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.deals}/${deal.id}`}
                      >
                        Show more
                      </ButtonLink>
                      <ButtonLink
                        color={'blue'}
                        to={`${Paths.organizations}/${deal}`}
                      >
                        Company
                      </ButtonLink>
                    </StyledActionsRow>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </ResponsiveTable>
        </Card>
      </InfiniteScroll>
    </MainLayout>
  );
};

export default Deals;
