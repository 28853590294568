import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { API_URL } from '../../shared/constants';

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    if (result.error.status === 401) {
      const refreshResult = await baseQuery(
        { url: `/users/refresh_token`, method: 'POST' },
        api,
        extraOptions,
      );
      if (refreshResult?.meta?.response?.status === 200) {
        result = await baseQuery(args, api, extraOptions);
      } else {
        await baseQuery(
          { url: `/users/logout`, method: 'POST' },
          api,
          extraOptions,
        );
      }
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Auth', 'Organization', 'User', 'Order', 'Deal', 'Lead'],
  endpoints: () => ({}),
});
