import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ordersApi } from '../services/OrdersService';
import { Order } from '../../shared/types/order';

interface IOrdersSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  orders: Order[];
}

const initialState: IOrdersSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  orders: [],
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      ordersApi.endpoints.getOrders.matchFulfilled,
      (state: IOrdersSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0) return { ...payload, orders: payload.orders };
        return { ...payload, orders: [...state.orders, ...payload.orders] };
      },
    );    
  },
});

export default slice.reducer;

export const selectOrders = (state: RootState) =>
  state.orders.orders;
export const selectOrdersTotalEntities = (state: RootState) =>
  state.orders.totalEntities;
export const selectOrdersHasMore = (state: RootState) => {
  const total = state.orders.totalEntities;
  return (
    total === null ||
    (total > 0 && state.orders.orders?.length < total)
  );
};
