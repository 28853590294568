import React from 'react';
import { User } from '../../shared/types/user';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { Organization } from '../../shared/types/organization';
import { StyledSuspendedText } from './styled';

interface IUserInfo {
  user: User;
  organization?: Organization;
}

const UserInfo: React.FC<IUserInfo> = ({ user, organization }) => {
  return (
    <>
      {!user?.isActive && (
        <StyledSuspendedText>Profile is suspended</StyledSuspendedText>
      )}
      <InfoValue value={user?.firstName} label={'First Name'} />
        <InfoValue value={user?.lastName} label={'Last Name'} />
      <InfoValue value={user?.email} label={'Email'} />
      <InfoValue value={organization?.name} label={'Company'} />
      <InfoValue value={user?.lang} label={'Language'} />
      <InfoValue value={toPrettyDate(user?.createdAt)} label={'Created'} />
    </>
  );
};

export default UserInfo;
