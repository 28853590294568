import styled from "@emotion/styled";
import { Flex, Grid, Text } from '@chakra-ui/react';
import { themeColorPicker, themeValue } from "../utils/themeSelectors";
import Container from "./ui/Container/Container";
import { css } from "@emotion/react";

export const StyledMainLayout = styled(Flex)`
  > div:last-of-type {
    flex: 1 1;  
  }
  
  @media (max-width: ${themeValue('media')('m')}) {
    position: relative;
    display: block;
  }
`;

export const StyledContentContainer = styled(Container)`
  background-color: ${themeColorPicker('gray06')};
  border-top-left-radius: 1rem;
`;

export const StyledSidebar = styled(Flex)<{ isOpen: boolean }>`
  background-color: ${themeColorPicker('white01')};
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-x: hidden;
  transition: 0.3s;
  width: ${({ isOpen }) => isOpen && '15rem' || 0};
  min-width: ${({ isOpen }) => isOpen && '15rem' || 0};
  flex-direction: column;
  box-shadow: ${themeValue('shadows')('xs')};
  white-space: nowrap;

  @media (max-width: ${themeValue('media')('l')}) {
    position: absolute;
    z-index: 3;
    width: 15rem;
    margin-left: ${({ isOpen }) => (isOpen && '0rem') || '-15rem'};
    transition: all 0.4s;
  }
`;

export const StyledSidebarBackdrop = styled.div<{ isOpen: boolean }>`
  transition: visibility 0s, opacity 0.5s linear;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${themeColorPicker('black01', 0.5)};
  z-index: 1;

  ${({ isOpen }) => isOpen && css`
    visibility: visible;
    opacity: 1;
  ` || css`
    visibility: hidden;
    opacity: 0;
  `};

  @media (min-width: ${themeValue('media')('l')}) {
    display: none;
  }
`;

export const StyledNavLinks = styled(Grid)`
  padding: 1rem;
  grid-gap: 1.25rem;
`;

export const StyledProfileBox = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid ${themeColorPicker('gray04')};
  
  .chakra-avatar, .chakra-spinner {
    margin-right: 0.5rem;
  }
`;

export const StyledUserName = styled(Text)`
  font-size: ${themeValue('fontSizes')('sm')};
`;

export const StyledLogoutWrapper = styled.div`
  padding: 1rem;
  
  button {
    width: 100%;
  }
`;
