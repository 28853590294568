import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { baseApi } from './services/BaseService';
import auth from './slices/authSlice';
import users from './slices/usersSlice';
import deals from './slices/dealsSlice';
import organizations from './slices/organizationsSlice';
import orders from './slices/ordersSlice';
import leads from './slices/leadsSlice';

const rootReducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  auth,
  users,
  deals,
  organizations,
  orders,
  leads,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware),
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
