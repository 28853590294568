import styled from '@emotion/styled';
import { Grid, Text } from '@chakra-ui/react';
import {
  themeColorPicker,
  themeValue,
} from '../../shared/utils/themeSelectors';

export const StyledFiltersWrapper = styled(Grid)`
  grid-gap: 1rem;
  margin-bottom: 1rem;
  grid-template-columns: auto 1fr 1fr;

  @media (max-width: ${themeValue('media')('m')}) {
    grid-template-columns: auto 1fr;

    > div:nth-of-type(2) {
      display: none;
    }
  }
`;

export const StyledSelectedActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  grid-gap: 1rem;

  > div:first-of-type {
    flex: 1 1;
  }

  @media (max-width: ${themeValue('media')('m')}) {
    flex-direction: column;

    > div:first-of-type {
      display: none;
    }
  }
`;

export const StyledSelectedInfo = styled.div`
  > p:first-of-type {
    color: ${themeColorPicker('gray05')};
    font-size: ${themeValue('fontSizes')('md')};
  }
`;

export const StyledSuspendedText = styled(Text)`
  margin-top: 1rem;
  color: ${themeColorPicker('red01')};
  font-weight: bold;
  text-transform: uppercase;
`;

export const StyledSwitch = styled.div`
  display: grid;
  grid-template-columns: 12rem 1fr;
  align-items: center;
  grid-gap: 1rem;
`;

export const StyledInputsWrapper = styled.div`
  display: grid;
  grid-gap: 1rem;
`;
