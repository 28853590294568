import React from 'react';
import { Organization } from '../../shared/types/organization';
import OrganizationForm from './OrganizationForm';
import OrganizationInfo from './OrganizationInfo';
import Wrapper from '../../shared/components/Wrapper';
import { useEditMode } from '../../shared/hooks/useEditMode';
import EditModeSwitcher from '../../shared/components/EditModeSwitcher';

interface IOrganizationData {
    organization: Organization;
}

const OrganizationData: React.FC<IOrganizationData> = ({ organization }) => {
  const { edit, toggleEditMode, setEdit } = useEditMode();

  return (
    <Wrapper>
      <>
        <EditModeSwitcher toggleEditMode={toggleEditMode} edit={edit} />
        {(edit && <OrganizationForm organization={organization} setEdit={setEdit} />) || (
          <OrganizationInfo organization={organization} />
        )}
      </>
    </Wrapper>
  );
};

export default OrganizationData;
