export enum LeadStatus {
  UNKNOWN = 0,
  PLACED = 1,
  CONFIRMED = 2,
  DEPOSITED = 3,
  REJECTED = 4,
  TRASHED = 5,
  DUPLICATED = 6,
  POSTPONED = 7,
}
