import React from 'react';
import { useParams } from 'react-router-dom';
import { ordersApi } from '../../store/services/OrdersService';
import MainLayout from '../../shared/components/MainLayout';
import OrderData from './OrderData';
import { Flex, Spinner } from '@chakra-ui/react';

const Order: React.FC = () => {
  const { orderId } = useParams();
  const { data } = ordersApi.useGetOrderByIdQuery({
    orderId: Number(orderId) || 0,
  });

  return (
    <MainLayout>
      {(data?.order && (
        <>
          <OrderData order={data?.order} />
        </>
      )) || (
        <Flex w={'100%'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      )}
    </MainLayout>
  );
};

export default Order;
