import React from 'react';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Organization } from '../../shared/types/organization';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';

interface IOrganizationInfo {
  organization: Organization;
}

const OrganizationInfo: React.FC<IOrganizationInfo> = ({ organization }) => {
  return (
    <>
      <InfoValue value={organization?.name} label={'Name'} />
      <InfoValue value={organization?.description} label={'Description'} />
      <InfoValue value={organization?.id} label={'Id'} />
      <InfoValue value={toPrettyDate(organization?.createdAt)} label={'Created'} />
    </>
  );
};

export default OrganizationInfo;
