import {
  GetAllOrganizationsResponse,
  GetOrganizationByIdResponse,
  UpdateOrganizationRequest,
} from '../responseTypes/organization';
import { baseApi } from './BaseService';

const baseUrl = 'organizations';

export const organizationsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllOrganizations: build.query<GetAllOrganizationsResponse, void>({
      query: () => ({
        url: baseUrl,
      }),
    }),
    getOrganizationById: build.query<GetOrganizationByIdResponse, { organizationId: number }>({
      query: ({ organizationId }) => ({
        url: `${baseUrl}/${organizationId}`,
      }),
      providesTags: ['Organization'],
    }),
    deleteOrganizationById: build.query<void, { organizationId: number }>({
      query: ({ organizationId }) => ({
        url: `${baseUrl}/${organizationId}`,
        method: 'DELETE',
      }),
      providesTags: ['Organization'],
    }),
    updateOrganizationById: build.mutation<
      GetOrganizationByIdResponse,
      { organizationId: number; data: UpdateOrganizationRequest }
    >({
      query: ({ organizationId, data }) => ({
        url: `${baseUrl}/${organizationId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['Organization'],
    }),
    createOrganization: build.mutation<
      GetOrganizationByIdResponse,
      { data: UpdateOrganizationRequest }
    >({
      query: ({ data }) => ({
        url: `${baseUrl}/`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Organization',],
    }),

  }),
});
