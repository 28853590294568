import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { dealsApi } from "../services/DealsService";
import {Deal} from "../../shared/types/deal";

interface IDealsSlice {
  currentLimit: number | null,
  currentOffset: number | null,
  totalEntities: number | null,
  deals: Deal[],
}

const initialState: IDealsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  deals: [],
}

const slice = createSlice({
  name: 'deals',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addMatcher(
        dealsApi.endpoints.getAllDeals.matchFulfilled,
      (state: IDealsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args
        if (offset === 0) return { ...payload, deals: payload.deals }
        return { ...payload, deals: [...state.deals, ...payload.deals] }
      },
    )
  },
});

export default slice.reducer;

export const selectDeals = (state: RootState) => state.deals.deals;
export const selectDealsTotalEntities = (state: RootState) => state.deals.totalEntities;
export const selectDealsHasMore = (state: RootState) => {
  const total = state.deals.totalEntities
  return total === null || (total > 0 && state.deals.deals.length < total)
};
