import { baseApi } from './BaseService';
import {
  GetAllLeadsResponse,
  GetLeadByIdResponse,
} from '../responseTypes/lead';

const baseUrl = 'leads';

export const leadsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getLeads: build.query<
      GetAllLeadsResponse,
      {
        sorting?: string;
        order?: string;
        limit?: number;
        offset?: number;
        query?: string;
      }
    >({
      query: (params) => ({
        url: baseUrl,
        params,
      }),
    }),
    getLeadById: build.query<GetLeadByIdResponse, { leadId: number }>({
      query: ({ leadId }) => ({
        url: `${baseUrl}/${leadId}`,
      }),
      providesTags: ['Lead'],
    }),
  }),
});
