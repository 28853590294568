import React from 'react';
import { Link } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Organization } from '../../shared/types/organization';
import InfoValue from '../../shared/components/InfoValue';
import { toPrettyDate } from '../../shared/utils/utils';
import { Order } from '../../shared/types/order';

interface IOrderData {
  order: Order;
}

const OrderInfo: React.FC<IOrderData> = ({ order }) => {
  return (
    <>
      <InfoValue value={order?.id} label={'Id'} />
      <InfoValue value={order?.flow} label={'Flow'} />
      <InfoValue value={order?.requestId} label={'Request Id'} />
      <InfoValue value={order?.dealId} label={'Deal Id'} />
      <InfoValue value={order?.phoneNumber} label={'Phone'} />
      <InfoValue value={order?.postponed} label={'Postponed'} />
      <InfoValue value={order?.userAgent} label={'User Agent'} />
      <InfoValue value={order?.userIp} label={'User Ip'} />
      <InfoValue value={order?.status} label={'Status'} />
      <InfoValue value={order?.category} label={'Category'} />
      <InfoValue value={order?.geo} label={'Geo'} />
      <InfoValue value={order?.email} label={'Email'} />
      <InfoValue value={order?.firstName} label={'First Name'} />
      <InfoValue value={order?.lastName} label={'Last Name'} />

      <InfoValue value={toPrettyDate(order?.createdAt)} label={'Created'} />
      <InfoValue value={toPrettyDate(order?.createdAt)} label={'Sent'} />
    </>
  );
};

export default OrderInfo;
