import { useState } from 'react';
import { ASC, DESC } from '../constants';

export const useListFilters = () => {
  const [sorting, setSorting] = useState('createdAt');
  const [order, setOrder] = useState(DESC);

  const sortBy = (field: string = ASC) => {
    if (sorting === field && order === ASC) {
      orderBy(DESC);
    } else if (sorting === field) {
      setSorting(sorting);
      orderBy(ASC);
    } else {
      setSorting(field);
      orderBy(ASC);
    }
  };

  const orderBy = (direction: string) => {
    setOrder(direction);
  };

  return { sorting, sortBy, order, orderBy };
};
