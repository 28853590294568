import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { dealsApi } from '../../store/services/DealsService';
import MainLayout from '../../shared/components/MainLayout';
import { Deal } from '../../shared/types/deal';
import DealMainInfo from './DealMainInfo';

const DealDetails = () => {
  const { dealId } = useParams();
  const [dealData, setDealData] = useState<Deal | null>(null);
  const { data } = dealsApi.useGetDealByIdQuery({
    dealId: Number(dealId) || 0,
  });

  useEffect(() => {
    if (!!data) setDealData(data.deal);
  }, [data]);

  return (
    <MainLayout>
      {!!dealData && (
        <>
          <DealMainInfo deal={dealData} />
        </>
      )}
    </MainLayout>
  );
};

export default DealDetails;
