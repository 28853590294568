import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Lead } from '../../shared/types/lead';
import { leadsApi } from '../services/LeadService';

interface ILeadsSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  leads: Lead[];
}

const initialState: ILeadsSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  leads: [],
};

const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      leadsApi.endpoints.getLeads.matchFulfilled,
      (state: ILeadsSlice, { payload, meta }) => {
        const args = meta.arg.originalArgs;
        const { offset } = args;
        if (offset === 0) return { ...payload, leads: payload.leads };
        return { ...payload, leads: [...state.leads, ...payload.leads] };
      },
    );
  },
});

export default slice.reducer;

export const selectLeads = (state: RootState) => state.leads.leads;
export const selectLeadsTotalEntities = (state: RootState) =>
  state.leads.totalEntities;
export const selectLeadsHasMore = (state: RootState) => {
  const total = state.leads.totalEntities;
  return total === null || (total > 0 && state.leads.leads?.length < total);
};
