import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { usersApi } from '../services/UsersService';
import { GetUserByIdResponse } from '../responseTypes/user';
import {User} from "../../shared/types/user";

interface IUsersSlice {
  currentLimit: number | null;
  currentOffset: number | null;
  totalEntities: number | null;
  users: User[];
}

const initialState: IUsersSlice = {
  currentLimit: 20,
  currentOffset: 0,
  totalEntities: null,
  users: [],
};

const slice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        usersApi.endpoints.getAllUsers.matchFulfilled,
        (state: IUsersSlice, { payload, meta }) => {
          const args = meta.arg.originalArgs;
          const { offset } = args;
          if (offset === 0) return { ...payload, profiles: payload.users };
          return {
            ...payload,
            profiles: [...state.users, ...payload.users],
          };
        },
      )
      .addMatcher(
        usersApi.endpoints.updateUserById.matchFulfilled,
        (state: IUsersSlice, { payload }) => {
          return {
            ...state,
            users: state.users.map(
              (profComp) =>
                (payload.user.id === profComp.id && {
                  ...profComp,
                  user: payload.user,
                }) ||
                profComp,
            ),
          };
        },
      );
  },
});

export default slice.reducer;

export const selectUsers = (state: RootState) => state.users.users;
export const selectUsersTotalEntities = (state: RootState) =>
  state.users.totalEntities;
export const selectUsersHasMore = (state: RootState) => {
  const total = state.users.totalEntities;
  return (
    total === null || (total > 0 && state.users.users.length < total)
  );
};
