import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Auth from '../modules/auth/Auth';
import Dashboard from '../modules/dashboard/Dashboard';
import { Paths } from './routes';
import NotFound from '../shared/components/NotFound';
import Users from '../modules/users/Users';
import Organizations from '../modules/organizations/Organizations';
import User from '../modules/users/User';
import Organization from '../modules/organizations/Organization';
import Deals from '../modules/deals/Deals';
import DealDetails from '../modules/deals/DealDetails';
import { useRouteGuard } from './hooks/useRouteGuard';
import { useIsAuthed } from '../shared/hooks/useIsAuthed';
import Orders from '../modules/orders/Orders';
import Order from '../modules/orders/Order';
import Leads from '../modules/leads/Leads';
import Lead from '../modules/leads/Lead';

const RootRouter = () => {
  useRouteGuard();
  const { isAuthed, isLoadingUser } = useIsAuthed();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthed && !isLoadingUser) navigate('/login');
  }, [isAuthed]);

  return (
    <Routes>
      <Route path={Paths.login} element={<Auth />} />
      <Route path={Paths.home} element={<Dashboard />} />
      <Route path={Paths.deals} element={<Deals />} />
      <Route path={Paths.deals + '/:dealId'} element={<DealDetails />} />
      <Route path={Paths.orders} element={<Orders />} />
      <Route path={Paths.orders + '/:orderId'} element={<Order />} />
      <Route path={Paths.leads} element={<Leads />} />
      <Route path={Paths.leads + '/:leadId'} element={<Lead />} />
      <Route path={Paths.users} element={<Users />} />
      <Route path={Paths.users + '/:profileId'} element={<User />} />
      <Route path={Paths.organizations} element={<Organizations />} />
      <Route
        path={Paths.organizations + '/:organizationId'}
        element={<Organization />}
      ></Route>
      <Route element={<NotFound />} />
    </Routes>
  );
};

export default RootRouter;
