import { baseApi } from './BaseService';
import {
  GetAllUsersResponse,
  GetUserByIdResponse,
  UpdateUserByIdRequest,
} from '../responseTypes/user';
import { DESC, PROFILES_SORTING_CREATION_DATE } from '../../shared/constants';

const baseUrl = 'users';

export const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAllUsers: build.query<
      GetAllUsersResponse,
      {
        limit: number;
        offset: number;
        sorting: string;
        order: string;
        query?: string;
      }
    >({
      query: ({
        limit = 20,
        offset = 0,
        sorting = PROFILES_SORTING_CREATION_DATE,
        order = DESC,
        query = '',
      }) => ({
        url: `${baseUrl}/paginated`,
        params: { limit, offset, sorting, order, query },
      }),
    }),
    getUserById: build.query<GetUserByIdResponse, { userId: number }>({
      query: ({ userId }) => ({
        url: `${baseUrl}/id/${userId}`,
      }),
      providesTags: ['User'],
    }),
    getCurrentUser: build.query<GetUserByIdResponse, void>({
      query: () => ({
        url: `${baseUrl}/myself`,
      }),
      providesTags: ['User'],
    }),
    updateUserById: build.mutation<
      GetUserByIdResponse,
      { userId: number; data: UpdateUserByIdRequest }
    >({
      query: ({ userId, data }) => ({
        url: `${baseUrl}/${userId}`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});
