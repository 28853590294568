import React from 'react';
import OrderInfo from './OrderInfo';
import Wrapper from '../../shared/components/Wrapper';
import {Order} from "../../shared/types/order";

interface IOrderData {
  order: Order;
}

const OrderData: React.FC<IOrderData> = ({ order }) => {

  return (
    <Wrapper>
      <>
        <OrderInfo order={order} />
      </>
    </Wrapper>
  );
};

export default OrderData;
